@charset "UTF-8";


// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;


// ==================================================
//  Youtube
// ==================================================
#youtube-wrap {
  display: block;
  width: 100%;
  height: auto;
  min-height: 630px;
  position: relative;
  overflow: hidden;
  padding-top: 43.0714%;

  @include mixin.sp {
    min-height: 315px;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
  }
}
#youtube {
  display: block;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 177.77777778vh; // 16:9 の幅→16 ÷ 9＝ 177.77%
  height: 56.25vw; // 16:9の幅 → 9 ÷ 16 = 56.25%
  min-height: 100%;
  min-width: 100%;
}